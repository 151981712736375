<template>
  <div class="news">
    <TopNav></TopNav>
    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>新闻动态</div>
            <div>
              用中标资讯、运营干货一键掌握
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>小蚂蚁新闻动态</div>
            <div>
              新闻动态抢先看，从权益输出到品牌赋能
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- pc开始 -->
    <div class="pcAll">
      <!-- nav切换开始 -->
      <div class="nav-tab">
        <span
          :class="this.tabNum == 1 ? 'tab-active' : ''"
          @click="(tabNum = 1), (page = 1)"
          >公司新闻</span
        >
        <!-- <div>1</div> -->
        <!-- <span
          :class="this.tabNum == 2 ? 'tab-active' : ''"
          @click="(tabNum = 2), (currentPage = 1)"
          >行业资讯</span
        > -->
      </div>
      <!-- nav切换结束 -->

      <!-- 新闻整体开始 -->
      <div class="allNews">
        <!-- 公司新闻开始 -->
        <div class="news-box" v-show="this.tabNum == 1">
          <div class="news-content">
            <ul class="news-list">
              <li
                class="news-item"
                v-for="(item, index) in newsList"
                :key="index"
                @click="godetail(item.id)"
              >
                <img :src="item.thumbnail" alt="" />
                <p class="item-title" style="padding: 20px;">
                  {{ item.title }}
                </p>
                <div class="crestedbox" style="padding: 20px 0;">
                  <p class="item-time">{{ item.created_at }}</p>
                  <p class="item-time">浏览量：{{ item.look }}</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 分页开始 -->
          <div class="page">
            <van-pagination
              v-model="page"
              :total-items="total"
              :items-per-page="page_size"
              force-ellipses
              @change="changepage"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>
          <!-- 分页结束 -->
        </div>
        <!-- 公司新闻结束 -->

        <!-- 行业资讯开始 -->
        <div class="info-box" v-show="this.tabNum == 2">
          <ul class="info-list">
            <li
              class="info-item"
              v-for="(item, index) in newsList"
              :key="index"
              @click="godetail(item.id)"
            >
              <img :src="item.thumbnail" alt="" />
              <div class="info-font">
                <p class="info-title">
                  {{ item.title }}
                </p>
                <p class="info-time">2022-02-18</p>
                <p class="info-intro">
                  现在很多商家都接入会员积分服务，但是在设置好规则后，却总是运营不好，那么问题到底出现在哪里呢？
                  1.积分规则不合理，舍不得积分兑换带来的成本
                  很多商家在设置积分规则时，把获取积分的门槛设置...
                </p>
              </div>
            </li>
          </ul>
          <!-- 分页开始 -->
          <div class="page">
            <van-pagination
              v-model="page"
              :total-items="total"
              :items-per-page="page_size"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>
          <!-- 分页结束 -->
        </div>
        <!-- 行业资讯结束 -->
      </div>
      <!-- 新闻整体结束 -->
    </div>
    <!-- pc结束 -->

    <!-- 移动开始 -->
    <div class="phoneAll">
      <ul class="newsList">
        <li
          class="newsItem"
          v-for="(item, index) in newsList"
          :key="index"
          @click="godetail(item.id)"
        >
          <img class="itemImg" :src="item.thumbnail" alt="" />
          <p class="itemTit">{{ item.title }}</p>
          <!-- <div class="itemFoot">
						<span class="itemTime">{{item.created_at}}</span>
						<span class="itemTime">{{item.look}}</span>
						<a>查看详情></a>
					</div> -->
          <div class="creatbox">
            <div class="crestedbox">
              <span class="itemTime">{{ item.created_at }}</span>
              <span class="itemTime" style="margin-left: 10px;"
                >浏览量：{{ item.look }}</span
              >
            </div>

            <a>查看详情></a>
          </div>
        </li>
      </ul>
      <!-- 分页开始 -->
      <div class="page">
        <van-pagination
          v-model="page"
          :total-items="total"
          :items-per-page="page_size"
          force-ellipses
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
      <!-- 分页结束 -->
    </div>
    <!-- 移动结束 -->

    <Bottom></Bottom>
  </div>
</template>

<script>
import "../assets/less/News.less";
import TopNav from "../components/topNav";
import Bottom from "../components/Bottom";
import axios from "axios";
var qs = require("qs");

export default {
  data() {
    return {
      currentPage: 1,
      currentPage2: 1,
      tabNum: 1,
      page: 1,
      page_size: 12,
      newsList: [],
      total: 0,
    };
  },
  created() {
    this.request({
      page: this.page,
      page_size: this.page_size,
    });
  },
  mounted() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  methods: {
    godetail(id) {
      this.$router.push({
        name: "newsDetail",
        query: {
          id: id,
        },
      });
    },
    changepage(e) {
      this.page = e;
      this.request({
        page: this.page,
        page_size: this.page_size,
      });
    },

    //调用接口获取新闻列表
    request(requestData) {
      console.log("requestData", requestData);
      let that = this;
      axios({
        method: "POST",
        url: "https://antwechat.yqhinfo.com/article/news",
        data: qs.stringify(requestData),
        // params: method === 'GET' || method === 'DELETE' ? params : null,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        baseURL: "https://yqhinfo.com",
      }).then(
        function(res) {
          if (res.data.code == 0) {
            that.newsList = res.data.data.list;
            that.total = res.data.data.total;
            console.log(res);
          } else {
            that.$toast({
              message: res.data.message,
            });
          }
        },
        function(error) {
          console.log(error);
        }
      );
    },
  },
  components: {
    TopNav,
    Bottom,
  },
};
</script>

<style lang="less" scoped>
/deep/.van-pagination {
  width: 300px;
  margin: 0 auto;
}
.creatbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crestedbox {
  display: flex;
  align-items: center;
}
</style>
